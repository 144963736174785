import * as React from "react"
import { useProductForm } from "../form/product-form-context"
import { convertStringCurrencyToNumber } from "../../../../utils/accounting"
import InputField from "../../../../components/molecules/input"

export interface IPricesProps {}

const Prices = (props: IPricesProps) => {
  const { register } = useProductForm()

  return (
    <div>
      {/* NEW Price Version */}
      <h6 className="inter-base-semibold mb-1">Price & coins</h6>
      <div className="flex flex-row flex-wrap gap-4  mb-base">
        <div>
          <label
            className="inter-small-regular text-grey-50 block max-w-[370px] mb-base"
            htmlFor="price"
          >
            Nominal price for a product.
          </label>
          <InputField
            id="price"
            label="Price"
            min={0}
            required
            className="w-full"
            tooltipContent="Basic price for current product."
            type="number"
            placeholder="10,00"
            prefix={"€"}
            name="metadata.price"
            ref={register({
              required: "Price is required",
              valueAsNumber: true,
              setValueAs: convertStringCurrencyToNumber,
            })}
          />
        </div>
        <div>
          <label
            className="inter-small-regular text-grey-50 block max-w-[370px] mb-base"
            htmlFor="coins"
          >
            Amount of coins for the product. (Default: 0)
          </label>
          <InputField
            id="coins"
            label="Coins"
            min={0}
            className="w-full"
            tooltipContent="Coins for current product."
            type="number"
            placeholder="1"
            name="metadata.coins"
            ref={register({
              valueAsNumber: true,
            })}
          />
        </div>
      </div>
    </div>
  )
}

export default Prices
