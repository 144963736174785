import * as React from "react"
import RawJSON from "../../../components/organisms/raw-json"
import General from "./sections/general"
import Prices from "./sections/prices"
import { GeneralBodyCard } from "../../../components/molecules/general-body-card/general-body-card"
import LoadingContainer from "../../../components/loading-container"
import { ImagesIcons } from "./sections/images-icons"

type ProductFormProps = {
  product?: any
  schools?: Array<any>
  courses?: Array<any>
  isEdit?: boolean
}

const ProductForm = ({
  product,
  isEdit = false,
  schools,
  courses,
}: ProductFormProps) => {
  return (
    <LoadingContainer isLoading={schools?.length == 0 || courses?.length == 0}>
      <GeneralBodyCard
        isEdit={isEdit}
        product={product}
        title="General"
        subtitle="To start selling, all you need is a name, university and basic price."
      >
        <div>
          <General
            initialProduct={product}
            schools={schools}
            courses={courses}
          />
        </div>
        <div>
          <Prices />
        </div>
        <div>
          <ImagesIcons product={product} />
        </div>
        <div className="mt-large">
          <RawJSON data={product} title="Raw product" />
        </div>
      </GeneralBodyCard>
    </LoadingContainer>
  )
}

export default ProductForm
