import useImperativeDialog from "../../../hooks/use-imperative-dialog"
import useNotification from "../../../hooks/use-notification"
import { useAdminUpdateProduct } from "medusa-react"
import { useNetwork } from "../../../hooks/use-network"
import { getErrorMessage } from "../../../utils/error-messages"
import TrashIcon from "../../fundamentals/icons/trash-icon"
import BodyCard from "../../organisms/body-card"
import StatusSelector from "../status-selector"
import React from "react"
import { ProductStatus } from "@medusajs/medusa"

export const GeneralBodyCard = ({ isEdit, product, ...props }) => {
  const dialog = useImperativeDialog()
  const notification = useNotification()
  const network = useNetwork()

  const onDelete = async () => {
    const shouldDelete = await dialog({
      heading: "Delete Product",
      text: "Are you sure you want to delete this product",
    })
    if (shouldDelete) {
      try {
        if (product?.images[0] && product?.images[0].url) {
          await network.deleteImageForProduct(product?.images[0].url)
        }
        await network.deleteProduct(product.id)
        notification("Success", "Product deleted successfully", "success")
      } catch (error) {
        notification("Error", getErrorMessage(error), "error")
      }
    }
  }

  const onStatusChange = async () => {
    // const newStatus: ProductStatus =
    //   product?.status === ProductStatus.PUBLISHED
    //     ? ProductStatus.DRAFT
    //     : ProductStatus.PUBLISHED
    // updateProduct.mutate(
    //   {
    //     status: newStatus,
    //   },
    //   {
    //     onSuccess: () => {
    //       const pastTense = newStatus === "published" ? "published" : "drafted"
    //       notification(
    //         "Success",
    //         `Product ${pastTense} successfully`,
    //         "success"
    //       )
    //     },
    //     onError: (err) => {
    //       notification("Ooops", getErrorMessage(err), "error")
    //     },
    //   }
    // )
  }

  const actionables = [
    {
      label: "Delete Product",
      onClick: () => onDelete(),
      variant: "danger" as const,
      icon: <TrashIcon />,
    },
  ]

  return (
    <BodyCard
      actionables={isEdit ? actionables : undefined}
      forceDropdown
      status={
        isEdit ? (
          <StatusSelector
            isDraft={product?.status === "draft"}
            activeState="Published"
            draftState="Draft"
            onChange={onStatusChange}
          />
        ) : undefined
      }
      {...props}
    />
  )
}
