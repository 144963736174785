import * as React from "react"
import { useProductForm } from "../../../domain/products/product-form/form/product-form-context"
import BodyCard from "../../organisms/body-card"
import Input from "../../molecules/input"
import Checkbox from "../../atoms/checkbox"
import IconTooltip from "../../molecules/icon-tooltip"
import { numberOrNull } from "../../../utils/form-helpers"
import { useState } from "react"

export interface IStylesProps {
  register: any
}

export function Styles(props: IStylesProps): JSX.Element {
  const { register } = props
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false)

  return (
    <>
      <h2 className="inter-base-semibold">Style composition</h2>
      <label
        htmlFor="styles-block"
        className="inter-small-regular text-grey-50 block max-w-[370px] mb-base"
      >
        Set all your styles desires for products here
      </label>
      <div
        id="styles-block"
        className="grid grid-cols-2 gap-x-8 gap-y-4 mb-large"
      >
        <div>
          <label
            htmlFor="position"
            className="inter-small-regular text-grey-50 block max-w-[370px] mb-base"
          >
            Position on the list (top to bottom / left to right)
          </label>
          <Input
            id="position"
            label="Position"
            name="styles.position"
            placeholder="0"
            type="number"
            min={0}
            ref={register({
              setValueAs: numberOrNull,
            })}
          />
        </div>
        <div>
          <label
            htmlFor="highlight"
            className="inter-small-regular text-grey-50 block max-w-[370px] mb-base"
          >
            Set up if the frame should be highlighted and what title should have
            in doing so
          </label>
          <div className="flex item-center gap-x-1.5 mb-small">
            <Checkbox
              name="styles.isHighlighted"
              ref={register}
              label="Is highlighted"
              onChange={(v) => {
                setIsHighlighted(!isHighlighted)
              }}
            />
            <IconTooltip content="When unchecked the frame will not be applied to this product" />
          </div>
          {isHighlighted && (
            <Input
              id="highlighted"
              label="Title for frame"
              name="styles.title"
              placeholder="Good choice"
              ref={register}
            />
          )}
        </div>
      </div>
    </>
  )
}
