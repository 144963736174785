import {RouteComponentProps, Router, useLocation} from "@reach/router"
import { navigate } from "gatsby"
import { useAdminCollections, useAdminCreateCollection } from "medusa-react"
import { useAdminProducts } from "medusa-react"
import React, { useEffect, useState } from "react"
import PlusIcon from "../../components/fundamentals/icons/plus-icon"
import BodyCard from "../../components/organisms/body-card"
import TableViewHeader from "../../components/organisms/custom-table-header"
import AddCollectionModal from "../../components/templates/collection-modal"
import CollectionsTable from "../../components/templates/collections-table"
import ProductTable from "../../components/templates/product-table"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"
import { useNetwork } from "../../hooks/use-network"
import EditProductPage from "./edit";
import NewProductPage from "./new";

const VIEWS = ["products"]

const ProductIndex: React.FC<RouteComponentProps>  = () => {
  const location = useLocation()
  const [view, setView] = useState("products")

  const notification = useNotification()
  const createCollection = useAdminCreateCollection()
  const { products } = useAdminProducts()
  const network = useNetwork()

  const [schools, setSchools] = useState<Array<any>>([])
  useEffect(() => {
    ;(async () => {
      const {schools} = await network.getSchools()
      setSchools(schools)
    })()
  }, [])

  useEffect(() => {
    if (location.search.includes("?view=collections")) {
      setView("collections")
    }
  }, [location])

  useEffect(() => {
    location.search = ""
  }, [view])

  const CurrentView = () => {
    switch (view) {
      case "products":
        return <ProductTable />
      default:
        return <CollectionsTable />
    }
  }

  const CurrentAction = () => {
    switch (view) {
      case "products":
        return [
          {
            label: "New Product",
            onClick: () => navigate("/a/products/new"),
            icon: (
              <span className="text-grey-90">
                <PlusIcon size={20} />
              </span>
            ),
          },
        ]
      default:
        return [
          {
            label: "New Collection",
            onClick: () => setShowNewCollection(!showNewCollection),
            icon: (
              <span className="text-grey-90">
                <PlusIcon size={20} />
              </span>
            ),
          },
        ]
    }
  }

  const [showNewCollection, setShowNewCollection] = useState(false)

  const handleCreateCollection = async (data, colMetadata) => {
    console.log(data)
    const schools = data.schools.map((school) => school.value)
    const products = data.products.map((product) => ({
      price: product.price,
      amount: product.amount,
      productId: product.productId,
    }))
    const metadata = colMetadata
      .filter((m) => m.key && m.value) // remove empty metadata
      .reduce((acc, next) => {
        return {
          ...acc,
          [next.key]: next.value,
        }
      }, {})
    const requestData = {
      ...data,
      schools,
      products,
      price: data.price, // Price in cents
      metadata: {
        ...metadata,
        styles: data.styles,
      },
    }
    // Remove styles property from request object
    delete requestData.styles

    // OLD VERSION to create collection
    await createCollection.mutateAsync(requestData, {
      onSuccess: ({ collection }: {collection: any}) => {
        notification("Success", "Successfully created collection", "success")
        navigate(`/a/collections/${collection.collection.id}`)
        setShowNewCollection(false)
      },
      onError: (err) => notification("Error", getErrorMessage(err), "error"),
    })
  }

  return (
    <>
      <div className="flex flex-col grow h-full">
        <div className="w-full flex flex-col grow">
          <BodyCard
            actionables={CurrentAction()}
            customHeader={
              <TableViewHeader
                views={VIEWS}
                setActiveView={setView}
                activeView={view}
              />
            }
          >
            <CurrentView />
          </BodyCard>
        </div>
      </div>
      {showNewCollection && (
        <AddCollectionModal
          onClose={() => setShowNewCollection(!showNewCollection)}
          onSubmit={handleCreateCollection}
          schools={schools}
          products={products}
          // collection={collections}
        />
      )}
    </>
  )
}

const Products = () => {
  return (
     <Router>
       <ProductIndex path="/" />
       <EditProductPage path=":id" />
       <NewProductPage path="new"  />
     </Router>
  )
}

export default Products
