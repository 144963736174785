import * as React from "react";
import InputHeader, {InputHeaderProps} from "../../fundamentals/input-header";
import {useImperativeHandle, useRef} from "react";
import InputContainer from "../../fundamentals/input-container";
import { default as CurrencyInputRef, CurrencyInputProps } from "react-currency-input-field";

export interface ICurrencyInputProps extends InputHeaderProps, CurrencyInputProps{
    key?: string
    props?: React.HTMLAttributes<HTMLDivElement>
}

/**
 * Currency input with settings for currency
 * @author Leonid Grudanov <leonidprofessor27@gmail.com>
 * @constructor
 */
export const CurrencyInput = React.forwardRef((
    {
        placeholder,
        label,
        name,
        key,
        required,
        onChange,
        onFocus,
        tooltipContent,
        tooltip,
        prefix,
        props,
        disabled,
        className,
        ...fieldProps
    }: ICurrencyInputProps,
    ref
) => {
    const inputRef = useRef(null)
    useImperativeHandle(ref, () => inputRef.current)

    return (
        <InputContainer
            className={className}
            key={name}
            onClick={() => !disabled && inputRef?.current?.focus()}
            disabledInput={disabled}
            {...props}
        >
            {label && (
                <InputHeader {...{ label, required, tooltipContent, tooltip }} />
            )}
            <div className="w-full flex items-center mt-1">
                {prefix &&
                    <span className="text-grey-40 mr-2xsmall">{prefix}</span>}
            <CurrencyInputRef
                id={name}
                name={name}
                className="bg-inherit outline-none outline-0 w-full remove-number-spinner leading-base text-grey-90 font-normal caret-violet-60 placeholder-grey-40"
                key={key || name}
                placeholder={placeholder || "placeholder"}
                allowDecimals={true}
                ref={inputRef}
                decimalSeparator=','
                decimalScale={2}
                groupSeparator='.'
                required={required}
                disabled={disabled}
                {...fieldProps}
            />
            </div>
        </InputContainer>
    )
})
