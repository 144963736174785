import axios, { AxiosInstance } from "axios"
import { IProduct } from "../domain/products/product-form/interfaces/product.inteface"

const backendUrl = process.env.GATSBY_MEDUSA_BACKEND_URL

/**
 * All requests to custom endpoint can appear here
 * To get class instance use the hook useNetwork()
 * @constructor
 * @example
 *      const network = useNetwork()
 *      network.createProduct(product)
 */
export class NetworkService {
  private httpClient: AxiosInstance

  constructor() {
    if (!backendUrl) {
      throw new Error(`Backend URL is not specified!`)
    }

    this.httpClient = axios.create({
      baseURL: backendUrl + "/admin",
      withCredentials: true,
    })

    this.httpClient.interceptors.response.use(
      (res) => {
        return res // Forward response
      },
      (err) => {
        const data = err.response?.data
        if (data && data.message) {
          throw new Error(data.message)
        }
        throw err // Forward error
      }
    )
  }

  /* --- API Requests --- */

  /**
   * Create product request
   * @param product
   */
  async createProduct(product: IProduct): Promise<IProduct> {
    const { data } = await this.httpClient.post<IProduct>("/products", product)
    return data.product
  }

  async getProduct(id: string): Promise<IProduct> {
    const { data } = await this.httpClient.get<IProduct>(`/products/${id}`)
    return data.product
  }

  async updateProduct(id: string, updateProduct: any): Promise<IProduct> {
    const { data } = await this.httpClient.post<IProduct>(
      `/products/${id}`,
      updateProduct
    )
    return data.product
  }

  async updateProductVariant(
    productId: string,
    variantId: string,
    updateVariant: any
  ): Promise<IProduct> {
    const { data } = await this.httpClient.post<IProduct>(
      `/products/${productId}/variants/${variantId}`,
      updateVariant
    )
    return data.product
  }

  async deleteProduct(productId: string): Promise<void> {
    await this.httpClient.delete(`/products/${productId}`)
  }

  /**
   * Return universities
   */
  async getSchools(): Promise<any> {
    try {
      const { data } = await this.httpClient.get("/school")
      return data
    } catch (e) {
      return []
    }
  }

  /**
   * Return courses
   */
  async getCourses(): Promise<any> {
    try {
      const { data } = await this.httpClient.get("/courses")
      return data
    } catch (e) {
      return []
    }
  }

  /**
   * Upload image to the server
   * @param formData
   * @return string Url to download
   */
  async loadImage(formData: FormData): Promise<any> {
    try {
      const { data } = await this.httpClient.post("/upload", formData)
      return data.url
    } catch (error) {
      console.log(error)
    }
  }

  async deleteImageForProduct(imageUrl: string): Promise<any> {
    const arr = imageUrl.split("/")
    const imageName = arr[arr.length - 1]
    try {
      await this.httpClient.delete(`/upload/${imageName}`)
    } catch (ex) {
      console.log(ex)
    }
  }
}
