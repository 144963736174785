export function convertToKebabCase(str: string): string{
    if (!str){
        return ""
    }
    return str.toLowerCase().trim().replaceAll(" ", "-")
}

export function convertToASCII(str:string): string{
    if (!str) {
        return ""
    }
    let result = str
    Object.keys(Non_ACSII_Letters_map).forEach(char => {
        result = result.replaceAll(char, Non_ACSII_Letters_map[char])
    })
    return result
}

const Non_ACSII_Letters_map = {
    'ä': 'ae',
    'ö': 'oe',
    'ü': 'ue',
    'ß': 'ss',
}
