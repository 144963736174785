import React, { useEffect, useState } from "react"
import { FieldValues } from "react-hook-form"
import toast from "react-hot-toast"
import Spinner from "../../components/atoms/spinner"
import Toaster from "../../components/declarative-toaster"
import FormToasterContainer from "../../components/molecules/form-toaster"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"
import { handleFormError } from "../../utils/handle-form-error"
import ProductForm from "./product-form"
import {
  formValuesToUpdateProductMapper,
  formProductValuesToUpdateVariantMapper,
  productToFormValuesMapper,
} from "./product-form/form/mappers"
import {
  ProductFormProvider,
  useProductForm,
} from "./product-form/form/product-form-context"
import { RouteComponentProps, useParams } from "@reach/router"
import { useNetwork } from "../../hooks/use-network"
import { IProduct } from "./product-form/interfaces/product.inteface"

const EditProductPage: React.FC<RouteComponentProps> = () => {
  const { id } = useParams()
  const notification = useNotification()
  const network = useNetwork()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [product, setProduct] = useState<IProduct | undefined>(undefined)
  const [schools, setSchools] = useState<Array<any>>()
  const [courses, setCourses] = useState<Array<any>>()
  useEffect(() => {
    ;(async () => {
      const { schools } = await network.getSchools()
      setSchools(schools)

      const { courses } = await network.getCourses()
      setCourses(courses)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        if (id) {
          const product = await network.getProduct(id)
          setProduct(product)
          setIsLoading(false)
        }
      } catch (error) {
        notification("Error", getErrorMessage(error), "error")
      }
    })()
  }, [])

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async (data) => {
    setSubmitting(true)

    if (data.images && data.images?.name) {
      if (product?.images[0] && product?.images[0].url) {
        await network.deleteImageForProduct(product?.images[0].url)
      }
      const formData = new FormData()
      formData.append("file", data.images)
      formData.append("fileName", data.images?.name)
      try {
        const url = await network.loadImage(formData)
        data.images = [url]
        data.thumbnail = url
        console.log("url:", data.images)
      } catch (error) {
        setIsLoading(false)
        notification("Error", getErrorMessage(error), "error")
      }
    }
    try {
      setIsLoading(false)
      const formattedData = formValuesToUpdateProductMapper(data)
      if (
        Array.isArray(formattedData.images) &&
        formattedData.images[0].url === product?.images[0]?.url
      ) {
        delete formattedData.images
      }
      const formattedVariants = formProductValuesToUpdateVariantMapper(
        data,
        product
      )
      await network.updateProduct(id, formattedData)
      const updatedProduct = await network.updateProductVariant(
        id,
        product?.variants[0]?.id,
        formattedVariants
      )
      setProduct(updatedProduct)
      notification("Success", "Product updated successfully", "success")
    } catch (error) {
      notification("Error", getErrorMessage(error), "error")
    } finally {
      setSubmitting(false)
    }
  }

  return isLoading || !product ? (
    <div className="w-full pt-2xlarge flex items-center justify-center">
      <Spinner size={"large"} variant={"secondary"} />
    </div>
  ) : (
    <ProductFormProvider
      product={productToFormValuesMapper(product)}
      onSubmit={onSubmit}
    >
      {schools && courses && product ? (
        <ProductForm
          product={product}
          schools={schools}
          courses={courses}
          isEdit
        />
      ) : (
        <></>
      )}
      <UpdateNotification isLoading={submitting} />
    </ProductFormProvider>
  )
}

const TOAST_ID = "edit-product-dirty"

const UpdateNotification = ({ isLoading = false }) => {
  const {
    formState,
    onSubmit,
    handleSubmit,
    resetForm,
    dropdownDirty,
    isNewImage,
  } = useProductForm()
  const [visible, setVisible] = useState(false)
  const [blocking, setBlocking] = useState(true)

  const onUpdate = (values: FieldValues) => {
    onSubmit({ ...values })
  }

  useEffect(() => {
    const timeout = setTimeout(setBlocking, 300, false)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (!blocking) {
      setVisible(formState.isDirty || dropdownDirty || isNewImage)
    }

    return () => {
      toast.dismiss(TOAST_ID)
    }
  }, [formState.dirtyFields, formState.isDirty, dropdownDirty, isNewImage])

  return (
    <Toaster
      visible={visible}
      duration={Infinity}
      id={TOAST_ID}
      position="bottom-right"
    >
      <FormToasterContainer isLoading={isLoading}>
        <FormToasterContainer.Actions>
          <FormToasterContainer.ActionButton
            onClick={handleSubmit(onUpdate, handleFormError)}
          >
            Save
          </FormToasterContainer.ActionButton>
          <FormToasterContainer.DiscardButton onClick={resetForm}>
            Discard
          </FormToasterContainer.DiscardButton>
        </FormToasterContainer.Actions>
      </FormToasterContainer>
    </Toaster>
  )
}

export default EditProductPage
