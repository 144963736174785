import * as React from 'react';
import SSelect from "../../molecules/select";
import {useEffect, useMemo, useState} from "react";
import {Control, useController} from "react-hook-form";

export interface ISchoolsProps{
    schools: Array<any>
    control: Control
    onSchoolChanged: (value: Array<any>) => void
}

export function Schools(props: ISchoolsProps): JSX.Element{
    const {control, schools, onSchoolChanged} = props
    const schoolsOptions = useMemo<Array<any>>(() => schools?.map((school) => ({
        label: school.name,
        value: school.id,
    })) || [], [schools])

    const {field: { ref }} = useController({
        name: 'schools',
        control,
        defaultValue: schoolsOptions
    })
    const [chosenSchools, setChosenSchools] = useState<typeof schoolsOptions>([])
    useEffect(() => {
        onSchoolChanged(chosenSchools)
    }, [chosenSchools])
    function handleOnSchoolChosen(value){
        setChosenSchools(value)
    }

    return (
        <div>
            <h2 className="inter-base-semibold">Universities</h2>
            <h6 className="inter-small-regular text-grey-50 block max-w-[370px] mb-base">
                Choose universities for that the collection comes up.
            </h6>
            <SSelect
                label="Universities"
                isMultiSelect
                placeholder="Select universities..."
                options={schoolsOptions}
                value={chosenSchools}
                required
                onChange={handleOnSchoolChosen}
                ref={ref}
            />
        </div>
    )
}
