import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { useProductForm } from "../form/product-form-context"
import FileUploadField from "../../../../components/atoms/file-upload-field"
import Input from "../../../../components/molecules/input"

const fileTypes = ["image/png", "image/jpeg"]

export interface IImagesIconsProps {
  product?: object | any
}

export const ImagesIcons = (props: IImagesIconsProps) => {
  const { product } = props
  const { register, setValue, changeNewImageState } = useProductForm()
  const inputRef = useRef(null)
  useEffect(() => {
    register("images")
  })

  const [imageState, setImageState] = useState(null)
  const [imageSrc, setImageSrc] = useState<string>("")

  useEffect(() => {
    if (product && product.images.length > 0) {
      ;(async () => {
        if (product.images[0].url) {
          setImageSrc(product.images[0].url)
        }
      })()
    }
  }, [])

  useEffect(() => {
    if (imageState) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImageSrc(reader.result as string)
      }
      reader.readAsDataURL(imageState)
    }
  }, [imageState])

  const handleImageUpload = async (files) => {
    setImageState(files[0])
    setValue("images", files[0])
    changeNewImageState()
  }

  return (
    <>
      <h6 className="inter-base-semibold mb-1 mt-4">Cover and icon</h6>
      <div className="flex flex-row flex-nowrap w-full gap-x-2 mb-base">
        <div className="w-full">
          <label className="inter-small-regular text-grey-50 block max-w-[370px] mb-base">
            Cover product image.
          </label>

          {imageState || imageSrc ? (
            <>
              <div
                onClick={() => inputRef?.current.click()}
                className={
                  "flex flex-col select-none inter-base-regular text-grey-50 cursor-pointer items-center justify-center w-full h-full rounded-rounded border-2 border-dashed border-grey-20 transition-colors hover:border-violet-60 hover:text-grey-40"
                }
              >
                <img src={imageSrc} alt="product-image" />
              </div>

              <input
                ref={inputRef}
                accept={fileTypes.join(", ")}
                type="file"
                onChange={(e) => handleImageUpload(e?.target?.files)}
                className="hidden"
              />
            </>
          ) : (
            <FileUploadField
              filetypes={fileTypes}
              onFileChosen={handleImageUpload}
            />
          )}
        </div>
        <div className="w-full">
          <label className="inter-small-regular text-grey-50 block max-w-[370px] mb-base">
            Manage icon for this product (Check here:&nbsp;
            <a
              className="text-blue-500 underline"
              href="https://fontawesome.com/icons"
              target="_blank"
            >
              class string
            </a>
            ).
          </label>
          <Input
            id="icon"
            label="Icon"
            name="metadata.icon"
            placeholder="font-icon"
            ref={register()}
          />
        </div>
      </div>
    </>
  )
}
