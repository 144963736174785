import React, { useEffect, useMemo, useState } from "react"
import Input from "../../../../components/molecules/input"
import Textarea from "../../../../components/molecules/textarea"
import { useProductForm } from "../form/product-form-context"
import {
  convertToASCII,
  convertToKebabCase,
} from "../../../../utils/text-builder"
import SSelect from "../../../../components/molecules/select"
import Checkbox from "../../../../components/atoms/checkbox"

export interface IGeneral {
  schools?: Array<any>
  courses?: Array<any>
  initialProduct?: any
}

const General = (props: IGeneral) => {
  const { schools, courses, initialProduct } = props
  const { register, watch, setValue, changeDropdownDirty } = useProductForm()

  useEffect(() => {
    console.log("Init product: ", initialProduct)
  }, [])
  const [isTitleChanging, setIsTitleChanging] = useState<boolean>(false)
  const [schoolsAsFilter, setSchoolsAsFilter] = useState<boolean>(false)

  const schoolsOptions = useMemo<Array<any>>(
    () =>
      schools?.map((school) => ({
        label: school.short_name,
        value: school.id,
      })) || [],
    [schools]
  )

  const [chosenSchools, setChosenSchools] = useState<typeof schoolsOptions>(
    () => {
      console.log("Schools:", schools)
      if (initialProduct && schools) {
        return schools
          .filter(
            (s) => initialProduct.metadata.schools?.includes(s.id) || false
          )
          .map((school) => ({
            label: school.short_name,
            value: school.id,
          }))
      } else {
        return []
      }
    }
  )

  const coursesOptions = useMemo<Array<any>>(
    () =>
      courses
        ?.filter((course) => {
          if (chosenSchools.length == 0 || !schoolsAsFilter) {
            return true
          }
          const schoolsIds = chosenSchools.map((cs) => cs.value)
          return schoolsIds.includes(course.school?.id)
        })
        .map((course) => ({
          label: `${course.title} ${
            course.school
              ? `(${course.school.short_name || course.school.name})`
              : ""
          }`,
          value: course.id,
        })) || [],
    [courses, chosenSchools, schoolsAsFilter]
  )

  const [chosenCourses, setChosenCourses] = useState<typeof coursesOptions>(
    () => {
      console.log("Courses:", courses)
      if (initialProduct && courses) {
        return courses
          .filter(
            (c) => initialProduct.metadata.courses?.includes(c.id) || false
          )
          .map((course) => ({
            label: course.title,
            value: course.id,
          }))
      } else {
        return []
      }
    }
  )

  function handleOnSchoolChosen(value) {
    setChosenSchools(value)
    setValue(
      "metadata.schools",
      value.map((sch) => sch.value)
    )
    changeDropdownDirty()
  }
  function handleOnCourseChosen(value) {
    setChosenCourses(value)
    setValue(
      "metadata.courses",
      value.map((sch) => sch.value)
    )
    changeDropdownDirty()
  }

  /* --- WATCHERS --- */
  const title = watch("title")
  useEffect(() => {
    if (title && isTitleChanging) {
      setValue("handle", convertToASCII(convertToKebabCase(title)))
    }
  }, [title])

  return (
    <div>
      <div className="mb-base">
        <h6 className="inter-base-semibold mb-1">Universities</h6>
        <label
          className="inter-small-regular text-grey-50 block w-full mb-base"
          htmlFor="school"
        >
          <div className="w-full flex flex-row flex-nowrap justify-between">
            Where the product is applied.
            <Checkbox
              label="Use schools as filter"
              checked={schoolsAsFilter}
              onChange={() => setSchoolsAsFilter(!schoolsAsFilter)}
            />
          </div>
        </label>
        <div id="school">
          <SSelect
            label="Universities"
            isMultiSelect
            name="metadata.schools"
            placeholder="Select universities..."
            options={schoolsOptions}
            value={chosenSchools}
            required
            onChange={handleOnSchoolChosen}
            ref={register("metadata.schools")}
          />
        </div>
      </div>
      <div className="mb-base">
        <h6 className="inter-base-semibold mb-1">Courses</h6>
        <label
          className="inter-small-regular text-grey-50 block max-w-[370px] mb-base"
          htmlFor="courses"
        >
          Choose what courses will be included in product.
        </label>
        <div id="courses">
          <SSelect
            label="Courses"
            isMultiSelect
            name="metadata.courses"
            placeholder="Select courses..."
            options={coursesOptions}
            value={chosenCourses}
            required
            onChange={handleOnCourseChosen}
            ref={register("metadata.courses")}
          />
        </div>
      </div>
      <h6 className="inter-base-semibold mb-1">Details</h6>
      <div className="flex flex-row gap-5 mb-base">
        <div>
          <label
            htmlFor="name"
            className="inter-small-regular text-grey-50 block max-w-[370px] mb-base"
          >
            Give your product a short and unique name. It doesn't show anywhere.
          </label>
          <Input
            id="name"
            label="Name"
            name="title"
            placeholder="Product Uni Mainz"
            required
            onFocus={() => setIsTitleChanging(true)}
            onBlur={() => setIsTitleChanging(false)}
            ref={register({
              required: "Name is required",
              minLength: 1,
              pattern: /(.|\s)*\S(.|\s)*/,
            })}
          />
        </div>
        <div>
          <label
            className="inter-small-regular text-grey-50 block max-w-[370px] mb-base"
            htmlFor="handle"
          >
            Handle is unique id that are appropriate for URL slugs. By default
            it's taking from name.
          </label>
          <Input
            id="handle"
            label="Handle"
            name="handle"
            placeholder="product-uni-mainz"
            prefix="/"
            ref={register()}
          />
        </div>
      </div>
      <label
        className="inter-small-regular text-grey-50 block max-w-[370px] mb-base"
        htmlFor="description"
      >
        Give your product a short and clear description. It's just for us and
        isn't used anywhere.
      </label>
      <div className="grid grid-rows-2 gap-x-8 gap-y-4 mb-large">
        <Textarea
          name="description"
          id="description"
          label="Description"
          placeholder="Short description of the product..."
          className="row-span-full "
          rows={4}
          ref={register()}
        />
      </div>
    </div>
  )
}

export default General
