import { useNetwork } from "./use-network"
import { useState } from "react"
import { useEffect } from "react"

export const useSchools = () => {
  const network = useNetwork()
  const [schools, setSchools] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    ;(async () => {
      const { schools } = await network.getSchools()
      setSchools(schools)
      setIsLoading(false)
    })()
  }, [])

  return {
    schools,
    isLoading,
  }
}
