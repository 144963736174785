import { IProduct } from "../interfaces/product.inteface"

export const productToFormValuesMapper = (product): IProduct => {
  return {
    title: product.title,
    handle: product.handle,
    status: product.status,
    description: product.description,
    metadata: {
      ...product.metadata,
      price: product.metadata.price / 100,
    },
    variants: product.variants,
    images: product.images,
  }
}

/**
 * For creating product
 * @param {object} values
 * @return {IProduct}
 */
export const formValuesToCreateProductMapper = (values): IProduct => {
  return {
    title: values.title,
    handle: values.handle,
    subtitle: values.subtitle || null,
    status: values.status || "published",
    description: values.description || null,
    variants: [
      {
        title: values.title,
        inventory_quantity: 1_000_000,
        prices: [
          {
            amount: values.metadata.price * 100,
            currency_code: "eur",
          },
        ],
      },
    ],
    images: values.images,
    metadata: {
      price: values.metadata.price * 100,
      schools: values.metadata.schools,
      courses: values.metadata.courses,
      icon: values.metadata.icon,
      coins: values.metadata.coins || 0,
    },
  }
}

/**
 * For updating product
 * @param {object} values
 * @return {IProduct}
 */
export const formValuesToUpdateProductMapper = (values): any => {
  return {
    title: values.title,
    handle: values.handle,
    subtitle: values.subtitle || null,
    status: values.status || "published",
    description: values.description || null,
    images: values.images,
    thumbnail: values.thumbnail,
    metadata: {
      price: values.metadata.price * 100,
      schools: values.metadata.schools,
      courses: values.metadata.courses,
      icon: values.metadata.icon,
      coins: values.metadata.coins || 0,
    },
  }
}

export const formProductValuesToUpdateVariantMapper = (
  values,
  product
): any => {
  const prices: Array<IPrice> = []
  prices.push({
    id: product?.variants[0]?.prices[0].id,
    amount: values.metadata.price * 100,
  })
  return {
    prices: prices,
    inventory_quantity: 1_000_000,
  }
}

interface IPrice {
  id: string | any
  amount: number
}
