import { Container } from 'typedi'
import {NetworkService} from "../services/network.service";

const networkService = new NetworkService()

/**
 * Provides NetworkService instance from TypeDI
 */
export function useNetwork(): NetworkService {
    return networkService
}
