import React, { useEffect, useState, useContext, createContext } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { trimValues } from "../../../../utils/trim-values"
import { IProduct } from "../interfaces/product.inteface"

export const VARIANTS_VIEW = "variants"

export const SINGLE_PRODUCT_VIEW = "single"

interface IProductForm {
  title: string
  handle: string
  description: string
  price: number
  status: string
  images: Array<string>
  metadata: {
    price: number
    schools: Array<number>
    courses: Array<number>
    icon?: string
  }
}

const defaultProduct: IProduct = {
  id: "",
  title: "",
  description: "",
  subtitle: null,
  handle: "",
  schools: [],
  isGiftcard: false,
  status: "",
  discountable: false,
  styles: {
    isHighlighted: false,
  },
  images: [],
  metadata: {
    price: 0,
    schools: [],
    courses: [],
  },
  variants: [],
}

const ProductFormContext = createContext<{
  dropdownDirty: boolean
  isNewImage: boolean
  changeDropdownDirty: () => void
  changeNewImageState: () => void
  onSubmit: (values: any) => void
  resetForm: () => void
} | null>(null)

export const ProductFormProvider = ({
  product = defaultProduct,
  onSubmit,
  children,
}) => {
  const [dropdownDirty, setDropdownDirty] = useState<boolean>(false)
  const [isNewImage, setIsNewImage] = useState<boolean>(false)

  const methods = useForm<IProductForm>({
    defaultValues: product,
  })

  const resetForm = () => {
    methods.reset({
      ...product,
    })
  }

  const changeDropdownDirty = () => {
    setDropdownDirty(true)
  }

  const changeNewImageState = () => {
    setIsNewImage(true)
  }

  useEffect(() => {
    resetForm()
  }, [product])

  const handleSubmit = (values) => {
    onSubmit({ ...trimValues(values) })
  }

  return (
    <FormProvider {...methods}>
      <ProductFormContext.Provider
        value={{
          onSubmit: handleSubmit,
          resetForm,
          dropdownDirty,
          isNewImage,
          changeDropdownDirty,
          changeNewImageState,
        }}
      >
        {children}
      </ProductFormContext.Provider>
    </FormProvider>
  )
}

export const useProductForm = () => {
  const context = useContext(ProductFormContext)
  const form = useFormContext()
  if (!context) {
    throw new Error("useProductForm must be a child of ProductFormContext")
  }
  return { ...form, ...context }
}
