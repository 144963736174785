import SSelect from "../../molecules/select";
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {CurrencyInput} from "../../molecules/currency-input/currency-input";
import Input from "../../molecules/input";
import PlusIcon from "../../fundamentals/icons/plus-icon";
import Button from "../../fundamentals/button";
import TrashIcon from "../../fundamentals/icons/trash-icon";
import {Control, useController} from "react-hook-form";

export interface IPricesProps{
    products: any
    control: Control
}
export interface IProductParams{
    id: number
    productId: string
    amount: number
    price: number
}

export function Prices(props: IPricesProps): JSX.Element{
    const {products, control} = props

    const {
        field: {
            onChange,
            value: productsList
        }
    } = useController({
        name: 'products',
        control,
        defaultValue: []
    })

    const productsOptions = useMemo<Array<any>>(() => products?.map((product) => ({
        label: product.title,
        value: product.id,
    })) || [], [products])

    function findProduct(id: string): any{
        return products.find(product => product.id === id)
    }

    function updateProduct(product: IProductParams): void{
        const indexUpdatedProduct = productsList.findIndex(pr => pr?.id === product?.id || pr === null)
        const newList = productsList
        if (indexUpdatedProduct >= 0) {
            newList.splice(indexUpdatedProduct, 1)
            onChange([...newList, product])
        }
        console.log(productsList)
    }

    function addEmptyProductToList(): void{
        if (!productsList.includes(null)){
            onChange([...productsList, null])
        }
    }

    function deleteProductFromList(idx: number): void{
        onChange(productsList.filter((_, i) => i != idx))
    }

    return (
        <div className="mb-large mt-large">
            <h2 className="inter-base-semibold">Product units</h2>
            <h6 className="inter-small-regular text-grey-50 block max-w-[370px] mb-base">
                Choose products that will be included as primary units for that the collection.
            </h6>
            {productsList.map((product, index) => (
                    <DeletableProduct
                        key={index}
                        id={index}
                        onChange={updateProduct}
                        productsOptions={productsOptions}
                        findProduct={findProduct}
                        onDelete={deleteProductFromList}/>
                ))}
            <Button
                variant="secondary"
                size="small"
                type="button"
                className="w-full mb-base"
                onClick={() => addEmptyProductToList()}
            >
                <PlusIcon size={20} />
                Add Product
            </Button>
        </div>
    )
}

interface IDeletableProductProps {
    id: number
    productsOptions: any
    onChange: (params: IProductParams) => void
    onDelete: (id: number) => void
    findProduct: (id: string) => any
}

function DeletableProduct(props: IDeletableProductProps): JSX.Element{
    const {id, productsOptions, onChange, onDelete, findProduct} = props
    const [chosenProduct, setChosenProduct] = useState<any>(null)
    const [amount, setAmount] = useState<number>(1)

    const calculatedPriceOnAmount = useMemo(() => {
        if (chosenProduct){
            const currentProduct = findProduct(chosenProduct.value)
            if (currentProduct){
                return currentProduct?.price * amount
            }
        }
        return ''
    }, [chosenProduct, amount])

    useEffect(() => {
        if (chosenProduct){
            onChange({
                id,
                productId: chosenProduct ? chosenProduct.value : '',
                price: calculatedPriceOnAmount || 0,
                amount: amount,
            })
        }
    }, [calculatedPriceOnAmount, amount, chosenProduct])

    return (
        <div>
            <div className=" flex flex-row flex-nowrap">
                <div className="mb-base grid grid-cols-3 gap-2" id="product-info">
                    <SSelect
                        label="Products"
                        placeholder="Select base product..."
                        options={productsOptions}
                        value={chosenProduct}
                        onChange={(v) => setChosenProduct(v)}
                        required
                    />
                    <Input
                        label="Products amount"
                        placeholder="0"
                        required
                        type="number"
                        min={1}
                        value={amount}
                        onChange={(v) => setAmount(+v.target.value)}
                    />
                    <CurrencyInput
                        label="Base price"
                        value={calculatedPriceOnAmount}
                        disabled
                        placeholder="0,00"
                        prefix='€'
                    />
                </div>
                <Button
                    variant="ghost"
                    size="small"
                    className="text-grey-40 justify-end"
                    type="button"
                    onClick={() => onDelete(id)}
                >
                    <TrashIcon size={20} />
                </Button>
            </div>
        </div>
    )
}

