import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { FieldValues } from "react-hook-form"
import toast from "react-hot-toast"
import Toaster from "../../components/declarative-toaster"
import FormToasterContainer from "../../components/molecules/form-toaster"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"
import { handleFormError } from "../../utils/handle-form-error"
import ProductForm from "./product-form"
import { formValuesToCreateProductMapper } from "./product-form/form/mappers"
import {
  ProductFormProvider,
  useProductForm,
} from "./product-form/form/product-form-context"
import { useNetwork } from "../../hooks/use-network"
import { RouteComponentProps } from "@reach/router"

const TOAST_ID = "new-product-dirty"

const NewProductPage: React.FC<RouteComponentProps> = () => {
  const notification = useNotification()
  const network = useNetwork()
  const [isLoading, setIsLoading] = useState(false)
  const [schools, setSchools] = useState<Array<any>>()
  const [courses, setCourses] = useState<Array<any>>()

  useEffect(() => {
    ;(async () => {
      const { schools } = await network.getSchools()
      setSchools(schools)

      const { courses } = await network.getCourses()
      setCourses(courses)
    })()
  }, [])

  const onSubmit = async (data) => {
    console.log("Values:", data)
    setIsLoading(true)

    if (data.images && data.images?.name) {
      const formData = new FormData()
      formData.append("file", data.images)
      formData.append("fileName", data.images?.name)
      try {
        const url = await network.loadImage(formData)
        data.images = [url]
      } catch (error) {
        setIsLoading(false)
        notification("Error", getErrorMessage(error), "error")
      }
    }
    // New version to create product
    const productBody = formValuesToCreateProductMapper(data)
    console.log(productBody)
    try {
      const product = await network.createProduct(productBody)
      console.log("Created:", product)
      setIsLoading(false)
      notification("Success", "Product was succesfully created", "success")
      navigate(`/a/products/${product.id}`)
    } catch (error) {
      setIsLoading(false)
      notification("Error", getErrorMessage(error), "error")
    }

    // Default version to create product
    //     createProduct.mutate(formValuesToCreateProductMapper(newData, viewType), {
    //       onSuccess: ({ product }) => {
    //         setIsLoading(false)
    //         notification("Success", "Product was succesfully created", "success")
    //         navigate(`/a/products/${product.id}`)
    //       },
    //       onError: (error) => {
    //         setIsLoading(false)
    //         notification("Error", getErrorMessage(error), "error")
    //       },
    //     })
  }

  return (
    <ProductFormProvider onSubmit={onSubmit}>
      {schools && courses ? (
        <ProductForm schools={schools} courses={courses} />
      ) : (
        <></>
      )}
      <SaveNotification isLoading={isLoading} />
    </ProductFormProvider>
  )
}

const SaveNotification = ({ isLoading = false }) => {
  const { formState, onSubmit, handleSubmit, resetForm } = useProductForm()
  const [visible, setVisible] = useState(false)

  const onPublish = (values: FieldValues) => {
    onSubmit({ ...values, status: "published" })
  }

  const onSaveDraft = (values: FieldValues) => {
    onSubmit({ ...values, status: "draft" })
  }

  useEffect(() => {
    if (formState.isDirty) {
      setVisible(true)
    } else {
      setVisible(false)
    }

    return () => {
      toast.dismiss(TOAST_ID)
    }
  }, [formState.dirtyFields, formState.isDirty])

  return (
    <Toaster
      visible={visible}
      duration={Infinity}
      id={TOAST_ID}
      position="bottom-right"
    >
      <FormToasterContainer isLoading={isLoading}>
        <FormToasterContainer.Actions>
          <FormToasterContainer.MultiActionButton
            actions={[
              {
                label: "Save and publish",
                onClick: handleSubmit(onPublish, handleFormError),
              },
              {
                label: "Save as draft",
                onClick: handleSubmit(onSaveDraft, handleFormError),
              },
            ]}
          >
            Save
          </FormToasterContainer.MultiActionButton>
          <FormToasterContainer.DiscardButton onClick={resetForm}>
            Discard
          </FormToasterContainer.DiscardButton>
        </FormToasterContainer.Actions>
      </FormToasterContainer>
    </Toaster>
  )
}

export default NewProductPage
