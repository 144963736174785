import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../fundamentals/button"
import IconTooltip from "../../molecules/icon-tooltip"
import InputField from "../../molecules/input"
import Modal from "../../molecules/modal"
import Metadata, { MetadataField } from "../../organisms/metadata"
import { Styles } from "./styles"
import { convertToASCII, convertToKebabCase } from "../../../utils/text-builder"
import { Schools } from "./schools"
import Textarea from "../../molecules/textarea"
import { IProductParams, Prices } from "./prices"
import { CurrencyInput } from "../../molecules/currency-input/currency-input"
import { convertStringCurrencyToNumber } from "../../../utils/accounting"

type CollectionModalProps = {
  onClose: () => void
  onSubmit: (values: any, metadata: MetadataField[]) => void
  isEdit?: boolean
  collection?: any
  schools: Array<any>
  products: Array<any> | any
}

interface ICollectionForm {
  title: string
  handle: string
  price: number
  description: string
  styles: {
    position: number
    isHighlighted: boolean
    title?: string
  }
  schools: Array<any>
  products: Array<IProductParams>
}

const CollectionModal: React.FC<CollectionModalProps> = ({
  onClose,
  onSubmit,
  isEdit = false,
  collection,
  schools,
   products,
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
  } = useForm<ICollectionForm>({
    defaultValues: {
      products: [],
    },
    mode: "all",
  })
  const [metadata, setMetadata] = useState<MetadataField[]>([])

  if (isEdit && !collection) {
    throw new Error("Collection is required for edit")
  }

  useEffect(() => {
    register("title", { required: true })
    register("handle")
  }, [])

  useEffect(() => {
    if (isEdit && collection) {
      setValue("title", collection.title)
      setValue("handle", collection.handle)

      if (collection.metadata) {
        Object.entries(collection.metadata).map(([key, value]) => {
          const newMeta = metadata
          newMeta.push({ key, value })
          setMetadata(newMeta)
        })
      }
    }
  }, [collection, isEdit])

  const submit = (data: any) => {
    onSubmit(data, metadata)
  }

  /* --- WATCHERS --- */
  const title = watch("title")
  useEffect(() => {
    if (title) {
      setValue("handle", convertToASCII(convertToKebabCase(title)))
    }
  }, [title])

  const productsList = watch("products")
  useEffect(() => {
    if (productsList.length > 0) {
      const price = productsList.reduce(
        (acc, pr: any) => acc + (pr?.price || 0),
        0
      )
      console.log(price)
      setValue("price", price + "")
    }
  }, [productsList])

  return (
    <Modal handleClose={onClose}>
      <Modal.Body>
        <Modal.Header handleClose={onClose}>
          <div>
            <h1 className="inter-xlarge-semibold mb-2xsmall">
              {isEdit ? "Edit Collection" : "Create Collection"}
            </h1>
            <p className="inter-small-regular text-red-400">
              NOTE: We are selling collections with products inside. The product
              is a base unit.
            </p>
            <p className="inter-small-regular text-grey-50">
              To create a collection, all you need is university, products and
              price for collection.
            </p>
          </div>
        </Modal.Header>
        <form onSubmit={handleSubmit(submit)}>
          <Modal.Content isLargeModal>
            <div>
              <h2 className="inter-base-semibold mb-base">Details</h2>
              <div className="flex items-center gap-x-base">
                <InputField
                  label="Title"
                  placeholder="Module or semester"
                  name="title"
                  required
                  ref={register}
                />
                <InputField
                  label="Handle"
                  placeholder="semester"
                  tooltip={
                    <IconTooltip content="URL Slug for the product. Will be auto generated if left blank." />
                  }
                  name="handle"
                  prefix="/"
                  ref={register}
                />
              </div>
            </div>
            <div>
              <h2 className="inter-base-semibold mt-base">Description</h2>
              <label
                className="inter-small-regular text-grey-50 block mb-base"
                htmlFor="description"
              >
                Give your collection a short and clear description.
              </label>
              <div className="mb-large">
                <Textarea
                  name="description"
                  id="description"
                  label="Description"
                  placeholder="Short description of the product..."
                  className="row-span-full "
                  rows={2}
                  ref={register}
                />
              </div>
            </div>
            <div className="mt-large">
              <Schools
                control={control}
                schools={schools}
                onSchoolChanged={(value) => setValue("schools", value)}
              />
            </div>
            <div>
              <Prices control={control} products={products} />
              <CurrencyInput
                id="price"
                name="price"
                label="Price for collection"
                tooltipContent="Price for current collection being shown to customers."
                placeholder="0,00"
                allowNegativeValue={false}
                prefix="€"
                ref={register({
                  setValueAs: convertStringCurrencyToNumber,
                  required: true,
                })}
              />
            </div>
            <div className="mt-large">
              <Styles register={register} />
            </div>
            <div className="mt-xlarge w-full">
              <Metadata setMetadata={setMetadata} metadata={metadata} />
            </div>
          </Modal.Content>
          <Modal.Footer>
            <div className="flex items-center justify-end w-full gap-x-xsmall">
              <Button
                variant="secondary"
                size="small"
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button variant="primary" size="small">
                {`${isEdit ? "Save" : "Publish"} collection`}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CollectionModal
